import { Aggregate, Summary, Statistics } from './types'


/* 
export type Summary = {
  domain: string;
  source_domain: string;
  count: number;
  spf_count: number;
  dkim_count: number;
  dmarc_count: number;
  //spf_rag?: string;
}

*/




export const dmarcStatistics = (reports:Summary[]) => { 

    let spf_count = 0;

    let stats:Statistics = {
        source_domains:     reports.length,
        count:              0,
        spf_count:          0,
        dkim_count:         0,
        dmarc_count:        0,
        spf100_spf_count:   0,
        spf100_dkim_count:   0,
        spf100_dmarc_count:   0,
    }

    reports.forEach((item) => {
        stats.count        += item.count;
        stats.spf_count    += item.spf_count;
        stats.dkim_count   += item.dkim_count;
        stats.dmarc_count  += item.dmarc_count;
        if (item.count === item.spf_count) {                        // only add to spf100 stats if 100% of this rpeort has good spf
            stats.spf100_spf_count   += item.spf_count;
            stats.spf100_dkim_count  += item.dkim_count;
            stats.spf100_dmarc_count += item.dmarc_count;
        }
    });

    return (stats);
}

