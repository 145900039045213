import { Button, Col, Form, Row, InputGroup, Tab, Tabs } from 'react-bootstrap';


import * as formik from 'formik';
import * as yup from 'yup';

import { typeValues,typeValuesFull, specialTypeValues } from './recordCheckTypes'


export const RecordCheckForm = (props:any) => {
  const { Formik } = formik;

  const hostName = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

  const number = /^[0-9]{1,3}$/gm

  const schema = yup.object().shape({
    tab: yup.string(),

    domain:       yup.string().required().matches(hostName,'Name must be a valid host name (e.g. example.com)'),
    simple:       yup.string().when('tab', { is: 'simple', then(schema) { return schema.required() } }),
    specialised:  yup.string().when('tab', { is: 'specialised', then(schema) { return schema.required() } }),
    free:         yup.string().when('tab', { is: 'free', then(schema) { return schema.required().matches(number,'Name must be a number <= 999')} }),
    //free:         yup.string().when('tab', { is: 'free', then(schema) { return schema.required().matches(number,'Entry must be a number') } }),

  });



  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false} 
      validateOnChange={false} 
      //onSubmit={console.log}
      initialValues={{
        domain: '',
        simple: '',
        specialised: '',
        free: '',
        tab: 'simple',
      }}
      onSubmit={(values:any) => ( props.setRequest({domain: values.domain, type: values[values.tab]} ))}  
    >

    {({ handleSubmit, handleChange, values, touched, errors, isValidating, isSubmitting }) => (       
        <Form noValidate onSubmit={handleSubmit} >

          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="domain">
              <Form.Label>Domain</Form.Label>
              <InputGroup>      
                <InputGroup.Text >http(s)://</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="domain"
                  name="domain"
                  value={values.domain}
                  onChange={handleChange}
                  isInvalid={!!errors.domain}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.domain?.toString()}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Tabs        
                onSelect={(event:any) => {values.tab = event; console.log('tab', values.tab)}}
                defaultActiveKey="simple"
                //id="uncontrolled-tab-example"
                className="mb-3"
                >
               
                <Tab eventKey="simple" title="Simple" >
                  <Form.Group as={Col} md="5" controlId="simple">
                    <Form.Label>Data Type</Form.Label>
                      <Form.Select
                              name='simple' 
                              aria-label="Select Type"
                              
                              value={values.simple}
                              onChange={handleChange}
                              isInvalid={!!errors.simple}
                              //isValid={(touched.simple && !errors.simple) || values.tab !== 'simple'}
                          >
                          <option key={'t1header'} value={''}>Open this select menu</option>
                          { typeValues.map((item:any) => (<option key={item.name} value={item.name}>{item.name}</option>)) }
                      </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.simple?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Tab>
               
                <Tab eventKey="specialised" title="SPF, DKIM, DMARC">
                  <Form.Group as={Col} md="5" controlId="specialised">
                    <Form.Label>Data Type</Form.Label>
                      <Form.Select 
                            name='specialised' 
                            aria-label="Select Type"
                          
                            value={values.specialised}
                            onChange={handleChange}
                            //isValid={touched.specialised && !errors.specialised}
                            isInvalid={!!errors.specialised}
                        >
        
                        <option key={'t2header' } value={''}>Open this select menu</option>
                        { specialTypeValues.map((item:any) => (<option key={item.name}  value={item.name}>{item.name}</option>)) }
                      </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.specialised?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Tab>

                <Tab eventKey="free" title="Free Format Number">
                  <Form.Group as={Col} md="5" controlId="free">
                    <Form.Label>Data Type</Form.Label>
                    <Form.Control 
                          type="number"
                          placeholder="Enter Number"
                          name="free"
                          value={values.free}
                          onChange={handleChange}
                          isInvalid={!!errors.free}
                        />
                    <Form.Control.Feedback type="invalid">
                      {errors.free?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Tab>     
          </Tabs>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    )}
    </Formik>
  );
}

