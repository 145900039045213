import { isTemplateExpression } from 'typescript';
import {ApiGet} from './Api'
import {Aggregate, DmarcReportRequest} from './types'

//const dns = require('dns');
//const dnsPromises = dns.promises;

export const getAll =  async (query: string) => {


    var Items:any[] = []; 


    // input = `${query}&Limit=10`;


    var next = ""; 
    var queryInput = "";

    var aggregate;

    do {
        queryInput = `${query}${next}`; //  for testing queryInput = `${query}${next}&Limit=2`;

        console.log('queryInput', queryInput);

        aggregate = await ApiGet(queryInput);

        console.log('dmarcReport stats', 
                    'Count:',            aggregate.Count, 
                    'LastEvaluatedKey:', aggregate.LastEvaluatedKey, 
                    'ScannedCount:',     aggregate.ScannedCount);


        Items = Items.concat(aggregate.Items);

        next = (aggregate.LastEvaluatedKey === undefined) ? "" : `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(aggregate.LastEvaluatedKey))}`;
 
 
    } while (aggregate.LastEvaluatedKey !== undefined);
  
    return(Items);
}




export const dmarcReport = async (search:DmarcReportRequest) => {

    var query:string = `DMARCreport?domain=${search.domain}`;

    if (search.hasOwnProperty('sk')) {

        query += `&sk=${encodeURIComponent(search.sk!)}`;
   
    } else if (search.hasOwnProperty('begin')) {

        // convert date time to interface format (no hours/mins/secs) output in seconds
        const startDate = new Date(search.begin!).toISOString().substring(0,10);
        const start:string = (new Date(startDate).getTime()/ 1000).toString(10);
    
        var end = start;
        
        if (search.hasOwnProperty('end')) {
            const endDate = new Date(search.end!).toISOString().substring(0,10);
            end = (new Date(endDate).getTime()/ 1000).toString(10);      
        }

        query += `&begin=${start}&end=${end}`;
    }
    

/*
    dns.reverse('149.72.226.45', (err, addresses) =>
    console.log('addresses: %j', addresses));

    dnsPromises.reverse('149.72.226.45').then((res) => { 
        console.log(res); 
    });

*/
    console.debug('Query', query);

    const Items = await getAll(query);

    console.log('Items', Items);

/*
    const aggregate = await ApiGet(`DMARCreport?domain=${domain}`);

    console.debug('XXXXXXX', aggregate);

    console.log('dmarcReport stats', 
                'Count:',            aggregate.Count, 
                'LastEvaluatedKey:', aggregate.LastEvaluatedKey, 
                'ScannedCount:',     aggregate.ScannedCount);

*/
/*
    var filtered :any[] = [];    
    Items.forEach((item:any) => {          
    //aggregate.Items.forEach((item:any) => {
        if (item.report_metadata.date_range.begin == 1695945600) {
            filtered.push(item)
            console.log('tem', item.record.length, item);
        }
    });   

    console.log('filtr', filtered);
*/
    //const denormalized =  denormalize_aggregate(aggregate.Items);  
    const denormalized =  denormalize_aggregate(Items);  

    console.log('denormalized', denormalized);

    const flattened =  flatten_aggregate(denormalized);  

    console.log('dmarcReport', flattened);
    console.log('dmarcReport count', denormalized.length, flattened.length);
    console.log('dmarcReport sizeof', JSON.stringify(denormalized).length, JSON.stringify(flattened).length);
    //console.log('dmarcReport count', aggregate.length, denormalized.length, flattened.length);
    //console.log('dmarcReport sizeof', JSON.stringify(aggregate).length, JSON.stringify(denormalized).length, JSON.stringify(flattened).length);

    return (flattened);
}

const denormalize_aggregate =  (aggregates: any) => {

    // aggregate[1..x].report[1..y] --> aggregate.report[1..z]
  
    var denormalized: any[] = [];

    for (const aggregate of aggregates) {             // for each aggregate report
      
        const header =  { report_metadata:   {...aggregate.report_metadata  }, 
                          policy_published:  {...aggregate.policy_published } };
            
        
        for (const record of aggregate.record) {       // for each record in aggregate
            
            denormalized.push({ ...header, record: {...record} });
            
        }
    }

    return (denormalized);
};

const flatten_aggregate = (parsed: object[]) => {      
    
    var filtered :Aggregate[] = [];
    var index=0;
    //const extracted = parsed.map ((item: any ) => { 
    const extracted = parsed.map ((item: any) => { 
     

       // console.log('auth', JSON.stringify(item.record.auth_results, null, 2));
        
        var startDate = new Date(item.report_metadata.date_range.begin*1000);
        var endDate   = new Date(item.report_metadata.date_range.end*1000);
        
        const dkim_auth = (item.record.auth_results.dkim === undefined) ? "" : item.record.auth_results.dkim.find((index:any) => index.domain == item.policy_published.domain);     
        const spf_auth  = (item.record.auth_results.spf  === undefined) ? "" : item.record.auth_results.spf[0];

        const newItem: Aggregate = {
            domain:             item.policy_published.domain,
            source_ip:          item.record.row.source_ip,
            count:              parseInt(item.record.row.count),
            delivery_status:    "",
            dmarc:              ((item.record.row.policy_evaluated.spf === "pass") || (item.record.row.policy_evaluated.dkim === "pass")) ? "pass" : "fail",
            spf:                item.record.row.policy_evaluated.spf,
            dkim:               item.record.row.policy_evaluated.dkim,            
            disposition:        item.record.row.policy_evaluated.disposition,
            spf_alignment:      ((item.record.row.policy_evaluated.spf === "fail") && (spf_auth.result === "pass")) ? "unaligned" : "aligned", // maybe not best fit may need deeper using evaulation criteria
            spf_authentication: spf_auth === undefined ? "fail (no match)" : `${spf_auth.result} (${spf_auth.domain})`,
            dkim_alignment:     (item.record.identifiers.header_from === item.policy_published.domain) ? "aligned": "not aligned",  // needs to check headers 
            dkim_authentication: dkim_auth === "" || dkim_auth === undefined ? "fail (no match)" : `${dkim_auth.result} (${dkim_auth.domain},${dkim_auth.selector})`,
            dkim_selector:       dkim_auth === "" || dkim_auth === undefined ? "" : dkim_auth.selector,
            reporter:           item.report_metadata.org_name,
            date:               `${startDate.toISOString().substring(0,10)}`,
            header_from:        item.record.identifiers.header_from,
            
            source_ptr:         "",
            source_domain:      "",

            end_date:            `${endDate.toISOString().substring(0,10)}`,
            envelope_from:      item.record.identifiers.envelope_from,
            envelope_to:        item.record.identifiers.envelope_to,
            sk:                 `begin#${item.report_metadata.date_range.begin}#end#${item.report_metadata.date_range.end}#report_id#${item.report_metadata.report_id}`,
  
            //date:               `${item.report_metadata.date_range.begin} - ${item.report_metadata.date_range.end}`,
            index:               index++,
        }
      
        /*

        if (newItem.source_ip == '23.83.212.17') { //&& newItem.date.substring(0,10) == '2023-09-29' ) {
            if (newItem.date.substring(0,10) == '2023-09-26') {
                console.log('000000 Monitor', newItem.date.substring(0,10), JSON.stringify(item,null,2));
            } else {
                console.log('111111 Monitor', newItem.date.substring(0,10), item);
            }

        }
        */
        /*
        if (newItem.source_ip == '149.72.167.101') { //&& newItem.date.substring(0,10) == '2023-09-29' ) {
            if (newItem.date.substring(0,10) == '2023-09-29' || newItem.date.substring(0,10) == '2023-09-28' ) {
                console.log('000000 Monitor', newItem.date.substring(0,10), JSON.stringify(item,null,2));
            } else {
                console.log('111111 Monitor', newItem.date.substring(0,10), item);
            }

        }
*/  
        if (newItem.date.substring(0,10) == '2023-09-29') 
        {
            filtered.push(newItem);
            console.log('start date', item.report_metadata.date_range.begin);
        }

        return newItem;
    });



    return (extracted);
}
