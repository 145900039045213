import {MenuOption, MenuButtonOption, RoutingValue} from './types'
import {ContentFsm} from './const'

import {Table} from './table'
import {Home} from './Home'
import {SignIn} from './SignIn'
import {SignOut} from './SignOut'
import {Catchall} from './Catchall'
import {ViewUser} from './ViewUser'
import {ViewDomain} from './ViewDomain'
import {ViewDNS} from './ViewDomainDetail'
import {RecordCheck} from './RecordCheck'


export const unauthOptions :MenuOption[] = [
                                      {path: '/home', title: 'Home'},
                                      {path: '/check', title: 'Check DNS settings'}  
                                    ]    


export const   authOptions :MenuOption[] = [
                                      {path: '/home', title: 'Home'},
                                      {path: '/check', title: 'Check DNS settings'} ,
                                      {path: '/results',  title: 'Results'},
                                      {path: '/user/domain',  title: 'My Domains'}
                                    ]    

export const   menuButtonCancel          :MenuButtonOption  = {path: '/cancel',  title: 'Cancel',  variant: 'warning', state: ContentFsm.REVALIDATE}
export const   menuButtonSignin          :MenuButtonOption  = {path: '/signin',  title: 'Signin',  variant: 'primary', state: ContentFsm.SIGNING_IN}        
export const   menuButtonSignout         :MenuButtonOption  = {path: '/signout', title: 'Signout', variant: 'secondary', state: ContentFsm.SIGNING_OUT}         
 

export const   revalidateRoutes     :RoutingValue[] = [
                                          {path: '*', component: Catchall}
                                        ]

export const   unauthRoutes         :RoutingValue[] = [
                                          {path: '/', component: Home},
                                          {path: '/home', component: Home},
                                          {path: '/signin', component: SignIn},
                                          {path: '/domain/*', component: ViewDNS},
                                          {path: '/check', component: RecordCheck},
                                          {path: '*', component: Home}
                                        ]

export const   authRoutes          :RoutingValue[] = [
                                          {path: '/', component: Home},
                                          {path: '/home', component: Home},
                                          {path: '/results', component: Table},
                                          {path: '/user/domain', component: ViewUser},     
                                          {path: '/user/domain/*', component: ViewDomain},
                                          {path: '/domain/*', component: ViewDNS},
                                          {path: '/check', component: RecordCheck},
                                          {path: '/signout', component: SignOut},
                                          {path: '*', component: Catchall}
                                        ]


// single damain paths
// component heirachy:  <ViewUser/> --> <ViewDomains/> --> <NavToViewDomain/> --> <ViewDomain/> --> <Domain/>
// paths                  /domain                                                    /domain/*             
