import { Aggregate } from './types'

interface dmarcFilterBySourceDomain {
    reports:Aggregate[];
    fieldName:keyof Aggregate;
    fieldValue:string;
}

export const dmarcFilterByField = (props:dmarcFilterBySourceDomain) => {

    const filtered:Aggregate[] = [];
    const fieldName = props.fieldName;
    const fieldValue = props.fieldValue;

    props.reports.forEach(report => {
        if (report[fieldName] === fieldValue) {
            filtered.push({...report});
        }
    });

    return (filtered);
};
