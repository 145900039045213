import { Aggregate, Summary } from './types'


/*
export type Summary = {
  domain: string;
  source_domain: string;
  count: number;
  spf_count: number;
  dkim_count: number;
  dmarc_count: number;
  spf_rag?: string;
}*/

export const dmarcSummary = (reports:Aggregate[]) => {

    const summary:Summary[] = [];       // indexed by source domain

    reports.forEach(report => {
        const index = summary.findIndex(item => item.source_domain === report.source_domain);

        if (index >= 0) {               // index used already so add to it
            summary[index] = {  ...summary[index], 
                                count:        summary[index].count       += report.count,
                                spf_count:    summary[index].spf_count   += (report.spf  === 'pass') ? report.count : 0,
                                dkim_count:   summary[index].dkim_count  += (report.dkim  === 'pass') ? report.count : 0,
                                dmarc_count:  summary[index].dmarc_count += (report.dmarc === 'pass') ? report.count : 0
                        
                            
                            };
        } else {                    // index is new so create it
            summary.push({source_domain: report.source_domain, 
                          domain:       report.domain,                                                      // assumed that this field is in avery report!
                          count:        report.count,
                          spf_count:    (report.spf   === 'pass') ? report.count : 0,
                          dkim_count:   (report.dkim  === 'pass') ? report.count : 0,
                          dmarc_count:  (report.dmarc === 'pass') ? report.count : 0
            });
        }
    });
    

    return (summary);

}