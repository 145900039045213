import {ApiGet} from './Api'
import {DmarcDomainRequest,DmarcDomainResponse} from './types'
import {getAll} from './dmarcReport'
import { isTypedArray } from 'util/types';

export const dmarcDomain = async (iplist:string[]) => {

    //const ipString = 

    const query:string = `DMARCdomain?iplist=${iplist}`;
/*
    expandAndReverseIp('');
    expandAndReverseIp('0123');
    expandAndReverseIp('01.23');
    expandAndReverseIp('01::23');
    expandAndReverseIp('1:2::3');
    expandAndReverseIp('1::2:3');
    expandAndReverseIp('1.2.3.4');
    expandAndReverseIp('1.2.3.4.5');
    expandAndReverseIp('1:2:3:4:5:6:7:8');
    expandAndReverseIp('1:2:3:4:5:6::7');
    expandAndReverseIp('1::2:3:4:5:6:7');
*/
    //const Items = await ApiGet(query);
    const Items = await LocalApiGet(iplist);
   // const Items = await getAll(query);


    console.log('Items', Items, query);

    return Items;
}

const LocalApiGet = async (iplist:string[]) => {

    //const split = ip.split;

    const ipArray = iplist; //iplist.split(',');

    console.log('LocalApiGet',iplist);

    const prefix = 'https://dns.google/resolve?type=PTR&name=';

    const urls = ipArray.map((ip) => {                                  // make a list of urls to call
        const suffix = ipv6(ip) ? ".ip6.arpa" : ".in-addr.arpa";
        const reverse = expandAndReverseIp(ip);
        const url = `${prefix}${reverse}${suffix}`;
        return url;
    });
     

     console.log('LocalApiGet',ipArray, urls);


     let list = urls.map((ip) => fetch(ip));                             // setup promises with fetch
    
     let fetchData:any[] = [];                                           // fetch all
     await Promise.all(list)
     .then(response => {
        fetchData = response.map((item) => item.json());
     })
     .catch(error => console.error(error));

    
    const output:DmarcDomainResponse[] = [];                             // get all results
    await Promise.all(fetchData)
    .then(result => {
        console.log('fetchData', result);

        for (let i=0; i<ipArray.length; i++) {
          
            const ip    = ipArray[i];
            const item  = result[i];

            const status = item.Status;
            //const host = item.Answer[0].data
        

            switch (status) {
                case 0: 
                    output.push({ip: ip, host: item.Answer[0].data, domain: getDomainName(item.Answer[0].data)});
                    break;
                case 3:
                    output.push({ip: ip, host: "not found", domain: "not found"});
                    break;
                default:
                    console.debug('DNS status not matched:', ip, status);
            }  
        }
    })
    .catch(error => console.error(error));

   // console.log('fetchData2 ++++++++++++++++++++++++++', result)




     //.then(data => console.log('fetch', data))
     //.catch(error => console.error(error));


    

/*
  for (let i=0; i<urls.length; i++) {
        fetch(urls[i])
        .then(response => response.json())
        .then(data => console.log('fetch', data))
        .catch(error => console.error(error));
     }

*/
    //const split = ip.split;
/*
    fetch("https://dns.google/resolve?type=PTR&name=8.4.0.1.0.0.0.0.0.0.0.0.0.0.0.0.0.2.0.0.4.6.8.4.0.b.8.f.7.0.6.2.ip6.arpa")
    //fetch("https://dns.google/resolve?type=PTR&name=92.100.89.167.in-addr.arpa")
    .then(response => response.json())
    .then(data => console.log('fetch', data))
    .catch(error => console.error(error));
*/
/*
    const dummy = split.map((item:string) => {

    });

*/
    //const dummy = [{ip: "", domain: "", host: ""}];

    console.log('dmarcDomain output:', output);

    return(output);

}



const getDomainName = (domain:string) => {
    // gets all TLDs and reasonable subset of eTLDs
    const trimDot = domain.replace(/\.$/, '');
    const split = trimDot.split('.').reverse();
    return (split.length >=3 && split[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|info|biz)$/i)) ? `${split[2]}.${split[1]}.${split[0]}` : `${split[1]}.${split[0]}`;
}


const ipv6 = (ip:string) => {
    const colons = (ip.match(/\:/g) || []).length;
    return (colons >= 2);
}

const expandAndReverseIp = (ip:string) => {
    const dots    = (ip.match(/\./g) || []).length;
    const colons  = (ip.match(/\:/g) || []).length;
    const doubles = (ip.match(/\::/g) || []).length;

    var output;

    if              (dots === 3) {                                              // ipv4 identified

        // reverse order
        output = ip.split('.').reverse().join('.');
 
    } else if   ( ( (doubles == 0) && (colons == 7)                  ) ||
                  ( (doubles == 1) && (colons >= 2) && (colons <= 7) )    ) {   // ipv6 identified

        // add shorthend :: leading zeros to each octet, convert to string, reverse order and put dots betweeen all chars
        //  e.g  1:2:3:4:5:6::7 --> 7.0.0.0.0.0.0.0.6.0.0.0.5.0.0.0.4.0.0.0.3.0.0.0.2.0.0.0.1.0.0.0
        //  e.g. 01::23         --> 3.2.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.0.1.0.0.0
        output = ip.replace('::', ':'+'0:'.repeat(8-colons)).split(':').map((item) => { return (item).padStart(4,'0') }).join('').split('').reverse().join('.');

    } else {
        output = ''; // error
    }

    //console.log('expandAndReverseIp', ip, output, dots, colons, doubles);
    return output;
}

 /*
            fetch("https://dns.google/resolve?type=PTR&name=8.4.0.1.0.0.0.0.0.0.0.0.0.0.0.0.0.2.0.0.4.6.8.4.0.b.8.f.7.0.6.2.ip6.arpa")
            //fetch("https://dns.google/resolve?type=PTR&name=92.100.89.167.in-addr.arpa")
            .then(response => response.json())
            .then(data => console.log('fetch', data))
            .catch(error => console.error(error));
*/ 