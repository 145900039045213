import {useContext, useEffect, useState, ReactElement, ComponentType, Fragment} from 'react';
import {DmarcContext} from './context';
import {dmarcUserDomains} from './dmarcUserDomains';
import { Icon } from './const';

//import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import { Table as BTable, Button } from 'react-bootstrap'


/*
declare module '@tanstack/react-table' {
  interface ColumnMeta {
    className?: string
  }
}
*/

import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  ExpandedState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  SortingState,
  useReactTable,
  CellContext,
  ColumnMeta,
} from '@tanstack/react-table'

/*
declare module '@tanstack/react-table' {
  interface ColumnMeta {
    className?: string
  }
}
*/

import { AnyAaaaRecord } from 'dns';
import { Framework } from '@aws-amplify/core';
import { TableCellProps } from '@aws-amplify/ui-react';
import { clearInterval } from 'timers';
/*
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
      className?: string
      getCellContext: (context: CellContext<TData, TValue>) => TableCellProps | void
  }
}
*/

const Green = '#0f0';

type ComponentProps = {
  data: any;
};

type TableDetail<T> = {
    columns: any[];
    data: T[];
    //Component?: ComponentType;
    Component?: ComponentType<ComponentProps>;
    tableId?: string;
};

export const TableDetail = <T,>({columns, data, Component, tableId=""}: TableDetail<T>) => {

////export const Tabs = <T,>({ active, list, onChange }: Props<T>): JSX.Element => {

//export default function Table() {
//export const TableDetail = ( props: {columns: any[], data: Domains[]}) => {
  
    const [sorting, setSorting] = useState<SortingState>([])
    const [expanded, setExpanded] = useState<ExpandedState>({})
  

    //const data:Domains[] = props.data;
    //const columns = props.columns;
  

    const table = useReactTable({

      data,
      columns,
      state: {
        sorting,
        expanded
      },
      //initialState: {
      //  columnVisibility: { padding: false },
      //},
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),

      onExpandedChange: setExpanded,
      getExpandedRowModel: getExpandedRowModel(), 

  
    })

    return (
  
      <>
      {console.debug('data',  data)}
  
  
      <div className="p-2">
        <BTable  bordered hover responsive size="sm"> {/*} <BTable striped bordered hover responsive size="sm"></BTable> */}
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={tableId+'hetr'+headerGroup.id}> 
                {headerGroup.headers.map(header => (           
                  <th
                    // @ts-ignore
                    className={(header.column.columnDef.hasOwnProperty('meta') && header.column.columnDef.meta!.hasOwnProperty('className')) ? header.column.columnDef.meta!.className : ""}
                    //className={(header.column.columnDef.hasOwnProperty('meta')) ? header.column.columnDef.meta!.className : ""}
                    //className={ header.column.columnDef.meta.className } 
                    key={tableId+'th'+header.id} 
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}         
                  >
                    {header.isPlaceholder ? null : (
                      <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                        {{
                              asc: Icon.ASC,
                              desc: Icon.DESC,
                          }[header.column.getIsSorted() as string] ?? null
                        }
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>        
            {table.getRowModel().rows.map(row => (
              <Fragment key={tableId+'frtr'+row.id}>
                <tr key={tableId+'tr'+row.id}>            
                  {row.getVisibleCells().map(cell => (                   
                      <Fragment key={tableId+'frtd'+cell.id}> 
                        {cell.column.columnDef.meta?.hasOwnProperty('TD') ? flexRender(cell.column.columnDef.cell, cell.getContext()) : <td className={tdClassName(cell)}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td> } 
                      </Fragment>
                  ))}
                </tr>              
                {row.getIsExpanded() && (Component !== undefined) ? <tr><td colSpan={99}><Component data={row.original}/></td></tr>: ''}             
                {/* row.getIsExpanded() && (Component !== undefined) ? <tr><td colSpan={columns.length}><Component data={row.original}/></td></tr>: ''}*/}         
              </Fragment> 
            ))}
          </tbody>
          {/*}
          <tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
          */}
        </BTable> 
      </div>  
      </>
    )
}

const tdClassName = (cell:any) => {
  return (cell.column.columnDef.hasOwnProperty('meta') && cell.column.columnDef.meta.hasOwnProperty('className')) ? cell.column.columnDef.meta!.className : "";
}


/*

                    <><RenderTD cell={cell} columnDef={cell.column.columnDef} getContext={cell.getContext()} /></>

      // @ts-ignore
                      <td key={cell.id} className={cell.column.columnDef.meta?.className}>
                       
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                 
                    */