// https://easydmarc.com/tools/dkim-lookup#:~:text=The%20v%3DDKIM1%20tag%20indicates,it%20to%20verify%20its%20authenticity.
const versionRegex = /^DKIM1/i;

type dkimCheckResponse = {
    mechanisms: any[];
    messages: string[];
    valid: boolean;
}

//  const values:ViewRecordValues[]  = result.hasOwnProperty('mechanisms') ? result.mechanisms.map((key:any) => ({name: key.type , value: key.value, description: key.description, prefix: key.prefix, prefixdesc: key.prefixdesc})) : [];
      

export const dkimCheck = (data: string) => {

    const messages:string[] = [];
    const mechanisms:any[] = [];


    const terms = data.trim().split(/;+/);
    const lhs = terms.map(term => (term.substring(0, term.indexOf('=')).trim()));
    const rhs = terms.map(term => (term.substring(term.indexOf('=')+1).trim()));
    const dedup = Array.from(new Set(lhs));

    console.debug('dkimCheck', data, terms, lhs, dedup, rhs);

    (lhs.length !== dedup.length) && messages.push('Duplicate tags are not allowed');
    (lhs.findIndex(tag => (tag === 'p')) === -1) && messages.push('p= public key tag (mandatory) not found');
    


   
        for (let i=0; i<lhs.length; i++) {
       
            (rhs[i] === '') && (lhs[i] !== '') && (messages.push(`No value specified for ${lhs[i]} tag`));

            switch (lhs[i]) {
                case 'p': 
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Public Key (mandatory)'});
                            break;

                case 'v':   (i !== 0)                       && messages.push('v= (version) must be the first tag if present');  
                            (!versionRegex.test(rhs[i]))    && messages.push('v= (version) must be "v=DKIM1"');  

                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Version (recommended)'});
                            break;
                case 't': 
                            (!(['y','Y','s','S'].includes(rhs[i])))  && messages.push('t= should be either "y" (test) or "s" (live)');  
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Flags (recommended) colon separated (only "y"(testing) & "s" are supported)'});
                            break;
                case 'g': 
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Granularity of the public key (optional)'});
                            break;
                case 's':
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Applicable Servce Types (optional) defaluts to "*"'});
                            break;            
                case 'h': 
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Acceptable hash algorithms(optional) - defaults to allow all'});
                            break;
                case 'k': 
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Key type (optional) - defaults to "rsa"'});
                            break;
                case 'n':   
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Notes for Administrators (optional)'});
                            break;  

                case '':    break;
                default:
                            messages.push('Unknown tag found');  
                            mechanisms.push({type: lhs[i], value: rhs[i], description: 'Unknown tag'});
                            break;  
            }
        }
    

    return {mechanisms, messages};
}



/*



*/