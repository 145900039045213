import { Aggregate, History } from './types'


/*
export type Hisory = {
  date: string,
  count: number;
  spf_count: number;
  dkim_count: number;
  dmarc_count: number;
*/

export const dmarcHistory = (reports:Aggregate[]) => {

    const history:History[] = [];       // indexed by source domain

    reports.forEach(report => {
        const index = history.findIndex(item => item.date === report.date);

        if (index >= 0) {               // index used already so add to it
            history[index] = {  ...history[index], 
                                count:        history[index].count       += report.count,
                                spf_count:    history[index].spf_count   += (report.spf  === 'pass') ? report.count : 0,
                                dkim_count:   history[index].dkim_count  += (report.dkim  === 'pass') ? report.count : 0,
                                dmarc_count:  history[index].dmarc_count += (report.dmarc === 'pass') ? report.count : 0
                        
                            
                            };
        } else {                    // index is new so create it
            history.push({date:         report.date, 
                          //domain:       report.domain,                                  // assumed that this field is in avery report!
                          count:        report.count,
                          spf_count:    (report.spf   === 'pass') ? report.count : 0,
                          dkim_count:   (report.dkim  === 'pass') ? report.count : 0,
                          dmarc_count:  (report.dmarc === 'pass') ? report.count : 0
            });
        }
    });
    

    return (history);

}