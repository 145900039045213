import {useContext, useEffect, useState} from 'react';
import {DmarcContext} from './context';
import {RAG} from './const';

import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Row, Spinner, Container } from 'react-bootstrap'

import {Aggregate, Summary } from './types'
import { TableDetail } from './TableDetail'


import { createColumnHelper, ColumnDef } from '@tanstack/react-table'


import { LoadingFsm } from './const'

import { dmarcFilterByField } from './dmarcFilter'





const columnHelper = createColumnHelper<Aggregate>();

const columns: any[] = [


        columnHelper.accessor('source_ip', {
         //accessorKey: 'source_ip',
          cell: info => info.getValue(),
          header: () => <span>Source IP</span>,
          id: 'source_ip',
          //footer: props => props.column.id,
        }),
        columnHelper.accessor('source_ptr', {
          cell: info => info.getValue(),
          header: () => <span>Source PTR</span>,
          id: 'source_ptr',
          //footer: props => props.column.id,
        }),
//        {
//          accessorKey: 'source_domain',
//          cell: info => info.getValue(),
//          header: () => <span>Source Domain</span>,
//          id: 'source_domain',
//          //footer: props => props.column.id,
//        },
        columnHelper.accessor('count', {
          cell: info => info.getValue(),
          header: () => <span>Count</span>,
          //footer: props => props.column.id,
        }),      
        columnHelper.accessor('delivery_status', {
          //accessorFn: row => row.spf,
          cell: info => info.getValue(),
          header: () => <span>Delivery Status</span>,
          //footer: props => props.column.id,
        }),       
        columnHelper.accessor('dmarc', {
          //accessorKey: 'dmarc',               // set colour
          //accessorFn: row => row.spf,
          //cell: info => info.getValue()
       
          cell: (info) =>  <td className={'ragTd'} style={{backgroundColor: (info.getValue() === 'pass'? RAG.Green : RAG.Red) }} >{info.getValue()}</td>,


          header: () => <span>DMARC</span>,
          //footer: props => props.column.id,
          meta: { 
              TD: null,
          }
        }), 
        columnHelper.accessor('spf', {
          //accessorFn: row => row.spf,
          //cell: info => info.getValue(),
          //cell: ({row, getValue}) =>  <td>getValue()</td>,

          cell: (info) =>  <td className={'ragTd'} style={{backgroundColor: info.getValue() === 'pass'? RAG.Green : RAG.Red }}>{info.getValue()}</td>,

          header: () => <span>SPF</span>,
          //footer: props => props.column.id,
          meta: { 
            TD: null,  
          }
        }),      
        columnHelper.accessor('dkim', {
          //cell: info => info.getValue(),
          cell: (info) =>  <td className={'ragTd'} style={{backgroundColor: (info.getValue() === 'pass'? RAG.Green : RAG.Red) }} >{info.getValue()}</td>,

          header: () => <span>DKIM</span>,
          //footer: props => props.column.id,
          meta: { 
            TD: null,  
          }
        }),    
        columnHelper.accessor('spf_alignment', {
          cell: info => info.getValue(),
          header: () => <span>SPF alignment</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('spf_authentication', {
          cell: info => info.getValue(),
          header: () => <span>SPF authentication result</span>,
          //footer: props => props.column.id,
        }),   
        columnHelper.accessor('dkim_alignment', {
          cell: info => info.getValue(),
          header: () => <span>DKIM alignment</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('dkim_authentication', {
          cell: info => info.getValue(),
          header: () => <span>dkim_authentication</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('dkim_selector', {
            cell: info => info.getValue(),
            header: () => <span>dkim_selector</span>,
            //footer: props => props.column.id,
        }),  
        columnHelper.accessor('reporter', {
          cell: info => info.getValue(),
          header: () => <span>Reporter</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('date', {
          cell: info => info.getValue(),
          header: () => <span>Date</span>,
          //footer: props => props.column.id,
        }),     
        columnHelper.accessor('header_from', {
          cell: info => info.getValue(),
          header: () => <span>from</span>,
          //footer: props => props.column.id,
        }),        
        columnHelper.accessor('end_date',  {
          cell: info => info.getValue(),
          header: () => <span>end</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('envelope_from', {
          cell: info => info.getValue(), 
          header: () => <span>envelope from</span>,
          //footer: props => props.column.id,
        }),        
        columnHelper.accessor('envelope_to', {
          cell: info => info.getValue(), 
          header: () => <span>envelope to</span>,
          //footer: props => props.column.id,
        }),  
        columnHelper.accessor('sk',  {
          cell: info => info.getValue(),
          header: () => <span>sk</span>,
          //footer: props => props.column.id,
        }), 
        columnHelper.accessor('index', {
          cell: info => info.getValue(),
          header: () => <span>index</span>,
          //footer: props => props.column.id,
        })
   //   ],
  //  },
  ]
  

interface ViewSender {
    data: Summary;
} 

export const ViewSender = (props:ViewSender) => {  
  const { dmarc, setDmarc } = useContext(DmarcContext);
  const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);

  console.debug('Domains', dmarc);

  const domain:string           = props.data.domain;
  const source_domain:string    = props.data.source_domain;

  const dmarcIndex = dmarc.findIndex(item => item.domain === domain);

  useEffect(() => {
      console.log('useEffect');
      //dmarcUserDomains()
      if (fsm ===  LoadingFsm.UNDEFINED) {
        if (dmarc[dmarcIndex].hasOwnProperty('report')) {
            setFsm((dmarc[dmarcIndex].report!.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
        } else {
            setFsm(LoadingFsm.FOUND);   
            
        }
    }  

  }, []);
      
  //console.log('++++++++++++ViewSender', columns, dmarc[dmarcIndex].report);
    
  console.log('ViewSender fsm:', fsm, dmarcIndex, props.data);

  switch (fsm) {
    case LoadingFsm.UNDEFINED:
    case LoadingFsm.INITIALISING:
    default:
      return (
        <Container fluid="lg" className="content_container">
          <Row>
              <Col>
                  <div> <Spinner  animation="border" /></div>
              </Col>
          </Row>
        </Container>
      );
      break; 
    case LoadingFsm.EMPTY:
      return (
        <Container fluid="lg" className="content_container">
          <Row>
              <Col>
                  <div>No Sender Data Found</div>
              </Col>
          </Row>
        </Container>
      );
      break; 
    case LoadingFsm.FOUND:

        const data = dmarcFilterByField({reports: dmarc[dmarcIndex].report!, fieldName: 'source_domain', fieldValue: source_domain});

        console.log('View Sender+++++++++++++++++++++',dmarc, data );

        return ( 
            <TableDetail columns={columns} data={data}/>
        );
    };
};
