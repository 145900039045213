import { History, Aggregate } from './types'

import { RAG } from './const'

import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { dmarcHistory } from './dmarcHistory'

import { dmarcSortHistory } from './dmarcSort'

const simpleDate = (text:string) => {
    const months = ['Jan', 'Feb', 'Mar','`Apr`','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ]
        
    const date = new Date(text);
    const month = months[date.getMonth()];
    const day   = date.getDate();

    //console.log('date', text, day, month);

    return(`${day}-${month}`)
};


interface ViewDomainHistory {
    domain: string;
    report: Aggregate[];
    //history: History[];
}

export const ViewDomainHistory = (props:ViewDomainHistory) => {

    const data = dmarcSortHistory(dmarcHistory(props.report));

    const chart:any = data.map((item) => ({date: simpleDate(item.date), passed: item.spf_count, failed: item.count-item.spf_count}));

    return (
        <>
            <h1>History</h1>

            <ResponsiveContainer width="95%" height={400}>
                <BarChart
                    //width={500}
                    height={300}
                    data={chart}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis height={100} dy={25} dx={-5} angle={270} dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Bar dataKey="passed" stackId="a" fill={RAG.Green} />
                    <Bar dataKey="failed" stackId="a" fill={RAG.Red} />
                </BarChart>
            </ResponsiveContainer>
    </>
    )
}

