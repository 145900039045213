export const specialTypeValues = [
    { name: 'SPF',   value:  16 },
    { name: 'DKIM',  value:  16 },
    { name: 'DMARC', value:  16 },
]
  
export const typeValuesFull = [
    { name: 'A', value:  1 }, // requires correct sub domain
    { name: 'NS', value:  2 },
    { name: 'CNAME', value:  5 }, // requires correct sub domain
    { name: 'SOA', value:  6 },
    { name: 'PTR', value:  12 },
    { name: 'HINFO', value:  13 },
    { name: 'MX', value:  15 },
    { name: 'TXT', value:  16 },


    { name: 'RP', value:  17 },
    { name: 'AFSDB', value:  18 },
    { name: 'SIG', value:  24 },
    { name: 'KEY', value:  25 },
    { name: 'AAAA', value:  28 }, // requires correct sub domain
    { name: 'LOC', value:  29 },
    { name: 'SRV', value:  33 },
    { name: 'NAPTR', value:  35 },
    { name: 'KX', value:  36 },
    { name: 'CERT', value:  37 },
    { name: 'DNAME', value:  39 },
    { name: 'APL', value:  42 },
    { name: 'DS', value:  43 },
    { name: 'SSHFP', value:  44 },
    { name: 'IPSECKEY', value:  45 },
    { name: 'RRSIG', value:  46 },
    { name: 'NSEC', value:  47 },
    { name: 'DNSKEY', value:  48 },
    { name: 'DHCID', value:  49 },
    { name: 'NSEC3', value:  50 },
    { name: 'NSEC3PARAM', value:  51 },
    { name: 'TLSA', value:  52 },
    { name: 'SMIMEA', value:  53 },
    { name: 'HIP', value:  55 },
    { name: 'CDS', value:  59 },
    { name: 'CDNSKEY', value:  60 },
    { name: 'OPENPGPKEY', value:  61 },
    { name: 'CSYNC', value:  62 },
    { name: 'ZONEMD', value:  63 },
    { name: 'SVCB', value:  64 },
    { name: 'HTTPS', value:  65 },
    { name: 'EUI48', value:  108 },
    { name: 'EUI64', value:  109 },
    { name: 'TKEY', value:  249 },
    { name: 'TSIG', value:  250 },
    { name: 'URI', value:  256 },
    { name: 'CAA', value:  257 },
    { name: 'TA', value:  32768 },
    { name: 'DLV', value:  32769 },
    { name: '*', value:  255 },
    { name: 'AXFR', value:  252 },
    { name: 'IXFR', value:  251 },
    { name: 'OPT', value:  41 },

   
    { name: 'SPF', value:  16 }, // special case
    { name: 'DMARC', value:  16 }, // special case
    { name: 'DKIM', value:  16 }, // special case
]

  
export const typeValues = [
    { name: 'A', value:  1 }, // requires correct sub domain
    { name: 'NS', value:  2 },
    { name: 'CNAME', value:  5 }, // requires correct sub domain
    { name: 'SOA', value:  6 },
  //  { name: 'PTR', value:  12 },
  //  { name: 'HINFO', value:  13 },
    { name: 'MX', value:  15 },
    { name: 'TXT', value:  16 },
  //  { name: 'RP', value:  17 },
  //  { name: 'AFSDB', value:  18 },
  //  { name: 'SIG', value:  24 },
  //  { name: 'KEY', value:  25 },
    { name: 'AAAA', value:  28 }, // requires correct sub domain
  //  { name: 'LOC', value:  29 },
  //  { name: 'SRV', value:  33 },
  //  { name: 'NAPTR', value:  35 },
  //  { name: 'KX', value:  36 },
  //  { name: 'CERT', value:  37 },
  //  { name: 'DNAME', value:  39 },
  //  { name: 'APL', value:  42 },
  //  { name: 'DS', value:  43 },
  //  { name: 'SSHFP', value:  44 },
  //  { name: 'IPSECKEY', value:  45 },
  //  { name: 'RRSIG', value:  46 },
  //  { name: 'NSEC', value:  47 },
  //  { name: 'DNSKEY', value:  48 },
  //  { name: 'DHCID', value:  49 },
  //  { name: 'NSEC3', value:  50 },
  //  { name: 'NSEC3PARAM', value:  51 },
  //  { name: 'TLSA', value:  52 },
  //  { name: 'SMIMEA', value:  53 },
  //  { name: 'HIP', value:  55 },
  //  { name: 'CDS', value:  59 },
  //  { name: 'CDNSKEY', value:  60 },
  //  { name: 'OPENPGPKEY', value:  61 },
  //  { name: 'CSYNC', value:  62 },
  //  { name: 'ZONEMD', value:  63 },
  //  { name: 'SVCB', value:  64 },
    { name: 'HTTPS', value:  65 },
  //  { name: 'EUI48', value:  108 },
  //  { name: 'EUI64', value:  109 },
  //  { name: 'TKEY', value:  249 },
  //  { name: 'TSIG', value:  250 },
  //  { name: 'URI', value:  256 },
    { name: 'CAA', value:  257 },
  //  { name: 'TA', value:  32768 },
  //  { name: 'DLV', value:  32769 },
    { name: '*', value:  255 },
  //  { name: 'AXFR', value:  252 },
  //  { name: 'IXFR', value:  251 },
  //  { name: 'OPT', value:  41 },
  ];
  