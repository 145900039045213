import { Button, Form, Tab, Tabs, Col } from 'react-bootstrap';
//import Form from 'react-bootstrap/Form';

import { dnsQuery } from './dnsQuery';

import { useContext, useEffect, useState, useCallback } from 'react';

import { createColumnHelper, CellContext } from '@tanstack/react-table'

import { TableDetail } from './TableDetail'
import { createTypeAssertion } from 'typescript';
import { format } from 'url';

import { dkimSelectors, Icon } from './const'


import { typeValues,typeValuesFull, specialTypeValues } from './recordCheckTypes'

import { RecordCheckForm } from './RecordCheckForm'

import { LoadingFsm } from './const'

type values = {
    domain:string;
    type:string;
    tab: string;
}

type dnsRequest = {
    domain:string;
    type:string;
}


// {domain: '', TXT: ''}

const recordQuery = async (dnsRequest:dnsRequest) => {
    console.log('recordQuery', dnsRequest);
   

     switch (dnsRequest.type) {
        case 'SPF': {
                const result = await dnsQuery(dnsRequest.domain, 'TXT')
                const filteredResult = {...result};
                const filteredAnswer:any = [];
                if (filteredResult.hasOwnProperty('Answer')) {
                    filteredResult.Answer.forEach((item:any) => {
                        if (item.data.startsWith('v=spf1')) {
                            filteredAnswer.push(item);
                        }
                    });
                }
                filteredResult.Question[0].type = dnsRequest.type;      // puts the 'pseudo' request type back in
                filteredResult.Answer = filteredAnswer;                 // removes non spf TXT records

                return([filteredResult]);
            }
            break;
        case 'DMARC': {   
                const result = await dnsQuery(`_dmarc.${dnsRequest.domain}`, 'TXT')
                const filteredResult = {...result};
                const filteredAnswer:any = [];
                //if (filteredResult.hasOwnProperty('Answer')) {
                    filteredResult.Answer.forEach((item:any) => {
                        if (item.data.startsWith('v=DMARC1')) {
                            filteredAnswer.push(item);
                        }
                    });
                //}
                filteredResult.Question[0].type = dnsRequest.type;      // puts the 'pseudo' request type back in
                filteredResult.Answer = filteredAnswer;                 // removes non DMARC TXT records

                return([filteredResult]);
            }
            break;
        case 'DKIM': {
                const next = async (selectorsRemaining:string[], results:any) => {       // *** RECURSIVE FUNCTION **
                    var resultsSoFar    = [...results];
                    const selectors     = [...selectorsRemaining];

                    const selector:string | undefined = selectors.shift();
                    
                    if (selector !== undefined) { 
                        const domain = `${selector}._domainkey.${dnsRequest.domain}`
                        const result = await dnsQuery(domain, 'TXT')
                        if (result.Answer !== undefined) {
                            result.Question[0].type = dnsRequest.type;      // puts the 'pseudo' request type back in
                            result.Question[0].data = `(selector = '${selector}')`;
                            resultsSoFar.push(result);
                        }
                        //const nextResult:any = await next(selectors, resultsSoFar);  // *** RECURSIVE CALL **
                        resultsSoFar = await next(selectors, resultsSoFar);  // *** RECURSIVE CALL **
                    } 

                    return (resultsSoFar);
                }


                let result:any = await next(dkimSelectors, []); // *** RECURSIVE CALL **

                result = (result.length === 0) ? [{Question:[ {name: dnsRequest.domain, type: dnsRequest.type}]}] 
                                               : result.sort((a:any,b:any) => (a.Status - b.Status) );                // sort failed items to the end
                

                //const sorted:any = result.sort((a:any,b:any) => (a.Status - b.Status) ); // sort failed items to the end

                //console.log('Final allResults', sorted, result);

                return(result);        
            }
            break;
        default:

            const result = await dnsQuery(dnsRequest.domain, dnsRequest.type)

            return ([result]);
          
    }
}

export const RecordCheck = () => {
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
    const [dnsValues, setDnsValues] = useState<dnsRequest>({domain: '', type: ''})
    const [results, setResults] = useState<any[]>([]);

    useEffect(() => {
        console.log('useEffect RecordCheck');
  
        if (fsm === LoadingFsm.UNDEFINED) {
            setFsm(LoadingFsm.FOUND);
        }


    }, []);
    

    useEffect(() => {

        const fetchData = async() => {
            const result = await recordQuery({...dnsValues})
            console.log('RESULT', result);
            setResults([...result]);
          };

        if ((fsm !== LoadingFsm.UNDEFINED) &&  (dnsValues.domain !== '')) {
              fetchData();
        }
    }, [dnsValues]);
    


    if (fsm === LoadingFsm.UNDEFINED) {
        return null;
    }
    
    return (
        <>
            <RecordCheckForm setRequest={setDnsValues}/>
            <RecordCheckResults query={results}/>
        </>
    )
}





const RecordCheckResults = (props:any) => {

    if (props.query.length === 0) return null;

    const expanded:any = [];
    const combined:any = [];
    
    props.query.forEach((query:any) => {
        const request  = (query.Question === undefined) ? [] : query.Question;
        const response = (query.Answer   === undefined) ? [] : query.Answer;

        const Xrequest = request.map((item:any) => ({result: (response.length !== 0), ...item }));
        
        const combo = Xrequest.concat(response);

        combo.forEach((item:any) => {expanded.push(item)});

        combined.push(combo);
    });


    console.log('COMBO', combined, expanded);

    let i=0;
    return (
        <>  
            <TableDetail key={i++} columns={recordCheckcolumns}  data={expanded} />
        
        </>
    )
};
//        {combined.map((query:any) => ( <TableDetail key={i++} columns={recordCheckcolumns}  data={query} /> ) )}
     

export type DNSQuery = {
    name: string;
    type: string;
}
  

const columnHelper = createColumnHelper<any>();

const recordCheckcolumns:any[] = [
    columnHelper.accessor('result', {
        size: 20,
        cell: ({getValue}) => { 
            switch (getValue()) {
                case true:  return (Icon.GOOD); break;
                case false: return (Icon.BAD);  break;
                default:
                    
                    return Icon.DOWNLOAD;
            }
        },
        header: () => null,
        enableSorting: false
    }),
    columnHelper.accessor('name', {
        size: 100,
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
        enableSorting: false
        //footer: props => props.column.id
    }),
    columnHelper.accessor('type', {
        size: 80,
        minSize: 80,
        maxSize: 200,
        //cell: info => info.getValue(),

        cell: ({getValue}) => { 
            const value     = getValue();
            let typeIndex;
            // adds number to text or vioce versa
            typeIndex = typeValuesFull.findIndex((item) => (item.value === value));
            if (typeIndex >= 0) {
                return (typeValuesFull[typeIndex].name+'\t('+value+')');                // adds text for the number
            } else {
                typeIndex = typeValuesFull.findIndex((item) => (item.name === value));
                if (typeIndex >= 0) {
                    return (value+'\t('+typeValuesFull[typeIndex].value+')');           // adds number for the text
                } 
            }
            return (value);                                                             // no match so just return thet raw value
        },

     

        header: () => <span>Type</span>,
        enableSorting: false
        //footer: props => props.column.id
    }),
    columnHelper.accessor('data', {
        size: 100,
        cell: info => info.getValue(),
        header: () => <span>Data</span>,
        enableSorting: false,
        //footer: props => props.column.id
        meta: { 
            className: 'table-overflowWrap',  
        }
    }),
    columnHelper.accessor('TTL', {
        size: 50,
        cell: info => info.getValue(),
        header: () => <span>TTL</span>,
        enableSorting: false
        //footer: props => props.column.id
    }),
    columnHelper.display( {

        id: 'padding',
        header: () => '',
        //footer: props => props.column.id
        meta: { 
            className: 'table-padding',  
        }
    })
]

