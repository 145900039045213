import {useContext, useEffect, useState} from 'react';
import {DmarcContext} from './context';
import {ViewDomains} from './ViewDomains';

export const ViewUser = () => {  


    console.debug('ViewUser');

    return ( 
        <ViewDomains />  
    );
};
