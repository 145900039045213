import { Aggregate, Summary, History } from './types'

export const dmarcSortReports = (report:Aggregate[]) => {
    // multi key (chained) sort
    report.sort((a,b) =>    a.source_domain.localeCompare(b.source_domain)  ||
                            a.source_ptr.localeCompare(b.source_ptr)        ||
                            a.source_ip.localeCompare(b.source_ip)          ||  
                            a.date.localeCompare(b.date)                         );
    return (report);
}


export const dmarcSortSummary = (report:Summary[]) => {

    report.sort((a,b) =>    b.count - a.count    );

    return (report);
}


export const dmarcSortHistory = (report:History[]) => {

    report.sort((a,b) =>    a.date.localeCompare(b.date)    );

    return (report);
}