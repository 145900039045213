import {useContext, useEffect, useState} from 'react';
import {DmarcContext} from './context';
import {dmarcUserDomains} from './dmarcUserDomains';
import {Icon} from './const';



import 'bootstrap/dist/css/bootstrap.min.css'
import { Table as BTable, Button, Container, Row, Col, Spinner } from 'react-bootstrap'

import { DomainsCols, DmarcData, Aggregate, Domains, Summary } from './types'
import { TableDetail } from './TableDetail'

import { Table } from './table'

import {ViewDomain, NavToViewDomain} from './ViewDomain'

import { createColumnHelper, CellContext } from '@tanstack/react-table'

import {dmarcReport} from './dmarcReport'

import { LoadingFsm } from  './const'
/*
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
      className?: string
      getCellContext: (context: CellContext<TData, TValue>) => TableCellProps | void
  }
}
*/
/*
declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
  }
}
*/
const columnHelper = createColumnHelper<DomainsCols>();
  

const columns:any[] = [

  columnHelper.display( {
    size: 40,
    header: ({ table }) => (
      <>
        <Button variant="outline-light"
          {...{
            onClick: table.getToggleAllRowsExpandedHandler(),
          }}
        >
          <div style={{color: '#000'}}>
              {table.getIsAllRowsExpanded() ? Icon.EXPANDED : Icon.CONTRACTED }
          </div>
        </Button>{' '}
      </>
    ),
    id: 'expand',
    cell: ({ row, getValue }) => (
      <div
        style={{
          // Since rows are flattened by default,
          // we can use the row.depth property
          // and paddingLeft to visually indicate the depth
          // of the row
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        <>
          {!row.getCanExpand() ? (
            <Button variant="outline-light"
              onClick={() => row.toggleExpanded()}
              {...{
                //onClick: row.getToggleExpandedHandler(),   
                style: { cursor: 'pointer' }
              }}
            >
              <div style={{color: '#000'}}>
                  {row.getIsExpanded() ? Icon.EXPANDED : Icon.CONTRACTED }
              </div>
            </Button>
          ) : (
            '🔵'
          )}{' '}
          {getValue()}
        </>
      </div>
    ),
  }),
  columnHelper.accessor('domain', {
    size: 200,
    //minSize: 40,
    //maxSize: 40,
    cell: info => info.getValue(),
    header: () => <span>Domain</span>,
    //footer: props => props.column.id
  }),
  columnHelper.display( {
  //  cell: () => (
  //    <div style={{backgroundColor: '#f00'}}>
  //      test
  //    </div>
  //  ),
  /*    <div
        style={{
          backgroundColor: 
        }}
      >
        <></>
      </div>
    ),

 */   
    id: 'padding',
    header: () => '',
    //footer: props => props.column.id
    meta: { 
        className: 'table-padding',  
    }
  })
]




export const ViewDomains = () => {  
    const { dmarc, setDmarc } = useContext(DmarcContext);
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);

    console.debug('Domains', dmarc);

    useEffect(() => {
      console.log('useEffect', dmarc.length);

      if (fsm === LoadingFsm.UNDEFINED) {
        if (dmarc.length > 0) {
          setFsm(LoadingFsm.FOUND);
          } else {
            setFsm(LoadingFsm.INITIALISING);     
            dmarcUserDomains()
            .then(report => { 
                setFsm((report.Items.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
                setDmarc([...dmarc, ...report.Items.map((item:any) => ({domain: item}))]);
                console.log('ViewDomains useEffect data', dmarc, report);
            });
          }
        }
      }, []);
  

    const domains =  dmarc.map(domain => ({domain: domain.domain})).sort((a,b) => a.domain.localeCompare(b.domain));

    console.log('ViewDomains fsm:', fsm);
    
    switch (fsm) {
      case LoadingFsm.UNDEFINED:
      case LoadingFsm.INITIALISING:
        return (
          <Container fluid="lg" className="content_container">
            <Row>
                <Col>
                    <div> <Spinner  animation="border" /></div>
                </Col>
            </Row>
          </Container>
        );
        break; 
      case LoadingFsm.EMPTY:
        return (
          <Container fluid="lg" className="content_container">
            <Row>
                <Col>
                    <div>No Domains Allocated</div>
                </Col>
            </Row>
          </Container>
        );
        break; 
      case LoadingFsm.FOUND:
      default:
        return ( 
            <TableDetail columns={columns} data={domains} Component={NavToViewDomain}/>
        );
    };
};


