import { RAG } from './const'
import { Row, Col } from 'react-bootstrap'

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    RadialBarChart,
    PolarAngleAxis,
    RadialBar
} from "recharts";

import { Statistics } from './types'

interface ViewDomainStatistcs {
    domain: string;
    statistics: Statistics;
}

export const ViewDomainStatistcs = (props:ViewDomainStatistcs) => {

    console.log('STATISTICS', props.statistics);

    return (
        <>
            <h2>Domain Information</h2>
            <p>Domain: {props.domain}</p>
            <p>Source Domains: {props.statistics.source_domains}</p>
            <p>Messsages: {props.statistics.count}</p>
            <p>DMARC Successs: {props.statistics.dmarc_count}</p>
            <p>SPF Success: {props.statistics.spf_count}</p>
            <p>DKIM Success: {props.statistics.dkim_count}</p>
            <Row>
                <Col>
                    <CircularProgress title={'All DMARC'} value={props.statistics.dmarc_count}                                              total={props.statistics.count} />
                </Col>
                <Col>
                     <CircularProgress title={'All SPF'} value={props.statistics.spf_count}                                                  total={props.statistics.count} />
                </Col>
                <Col>
                    <CircularProgress title={'All DKIM'} value={props.statistics.dkim_count}                                                total={props.statistics.count} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CircularProgress title={'DMARC'} value={props.statistics.spf100_dmarc_count}                                           total={props.statistics.spf100_spf_count} />
                </Col>
                <Col>
                    <CircularProgress title={'SPF'} value={props.statistics.spf100_spf_count}                                               total={props.statistics.spf100_spf_count} />
                </Col>
                <Col>
                    <CircularProgress title={'DKIM'} value={props.statistics.spf100_dkim_count}                                             total={props.statistics.spf100_spf_count} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CircularProgress title={'Forwarded DMARC'} value={props.statistics.dmarc_count-props.statistics.spf100_dmarc_count}    total={props.statistics.count-props.statistics.spf100_spf_count} />
                </Col>
                <Col> 
                    <CircularProgress title={'Forwarded SPF'} value={props.statistics.spf_count-props.statistics.spf100_spf_count}          total={props.statistics.count-props.statistics.spf100_spf_count} />
                </Col>
                <Col>
                    <CircularProgress title={'Forwarded DKIM'} value={props.statistics.dkim_count-props.statistics.spf100_dkim_count}       total={props.statistics.count-props.statistics.spf100_spf_count} /> 
                </Col>
            </Row>
        </>
    )

};

interface CircularProgress {
    title: string;
    value: number;
    total: number;
}


const CircularProgress = (props:CircularProgress) => {
    const color = rag(props.value / props.total);
    const progress = percent(props.value / props.total);

    const data = [
        { name: 'L1', value: progress }
    ];
    
  const circleSize = 110;

  return (
    <>
    <h2>{props.title}</h2>
    <p>{props.value} / {props.total}</p>
    <RadialBarChart
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={40}
        //outerRadius={50}
        barSize={10}
        data={data}
        startAngle={90}
        endAngle={-270}
        >
        <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
        />
        <RadialBar
        //background
        background={(progress === 0) ? { fill: color }: {} }
      
        dataKey="value"
        cornerRadius={circleSize / 2}
        fill={color}
        //fill="#82ca9d"
        />
        <text
        x={circleSize / 2}
        y={circleSize / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        >
        {progress}%
        </text>
    </RadialBarChart>
    </>
  )
}


const percent = (value:number) => {
    return(Math.floor(value*100));
}

const rag = (value:number) => {
    var color;
    switch (value) {
        case 0:   color = RAG.Red;          break;
        case 1:   color = RAG.Green;        break;
        default:  color = RAG.Amber;  
    }
    return(color);
}