import {useContext, useEffect, useState} from 'react';
import './App.css';
import {UserContext, MenuPathContext, DmarcContext} from './context'
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {Button, Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {awsconfig, environment} from './config';
import {MenuProps, DmarcData} from './types'
//import {MenuProps, DmarcData, DmarcDataRecord} from './types'
//import {MenuProps } from './types'

import {ContentFsm} from './const'
import {unauthOptions, authOptions, menuButtonCancel, menuButtonSignin, menuButtonSignout, revalidateRoutes, unauthRoutes, authRoutes} from './menusAndRoutes'

const MenuWrap = () => {
  const { authStatus} = useAuthenticator(context => [context.authStatus]);
  const { userStatus, setUserStatus } = useContext(UserContext);
  const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext)

  const navigate = useNavigate();

  useEffect(() => {
    console.debug('MenuWrap useEffect authStatus:', authStatus);
    setUserStatus((authStatus === 'authenticated') ? ContentFsm.SIGNED_IN : ContentFsm.SIGNED_OUT);
    navigate(lastMenuPath);
  }, [authStatus]);

  useEffect(() => {
    console.debug('MenuWrap useEffect userStatus:', userStatus);
    if (userStatus === ContentFsm.REVALIDATE) {
      setUserStatus((authStatus === 'authenticated') ? ContentFsm.SIGNED_IN : ContentFsm.SIGNED_OUT);
      console.debug('MenuWrap useEffect lastMenuPath:', lastMenuPath);
      navigate(lastMenuPath);
    }
  }, [userStatus]);


  console.debug('MenuWrap userStatus:', userStatus, 'authStatus:', authStatus, 'lastMenuPath:', lastMenuPath);

  var output;

  switch (userStatus) {
      case ContentFsm.REVALIDATE:       output = null; break;

      case ContentFsm.UNDEFINED:        output = (<Menu options={unauthOptions}/>);                       break;
      
      case ContentFsm.SIGNED_OUT:       output = (<Menu options={unauthOptions} button={menuButtonSignin}/>);   break;

      case ContentFsm.SIGNING_OUT:      output = (<Menu options={authOptions} button={menuButtonCancel}/>);     break;

      case ContentFsm.SIGNING_IN:       output = (<Menu options={unauthOptions} button={menuButtonCancel}/>);   break;

      case ContentFsm.SIGNED_IN:        output = (<Menu options={authOptions} button={menuButtonSignout}/>);    break;

      default:                          output = (<div><p>Unknown State {userStatus}</p></div>);
  }

  return ( 
    <>

      {output}

    </>
  );
}





const Menu = (props:MenuProps) => {
    const { userStatus, setUserStatus } = useContext(UserContext); 
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);
   
    const navigate = useNavigate();

    const menuSelect = (key:any) => {
      navigate(key);
      setLastMenuPath(key);
    };

    const buttonSelect = (params:any) => {
      const [key, newState] = params.split(',');
      console.debug('Menu buttonselect key:', key, 'newState:' ,newState);
      navigate(key);
      setUserStatus(newState)
    };


    const JSXmenu   = props.options.map(option => <Nav.Link eventKey={option.path} key={option.title}>{option.title}</Nav.Link>)
    const JSXbutton = (props.button === undefined) ? "" : <Nav.Link eventKey={`${props.button.path},${props.button!.state}`}><Button variant={props.button.variant}>{props.button.title}</Button></Nav.Link>


    return (
            <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                <Navbar.Collapse id="reponsive-navbar-nav" key="reponsive-navbar-nav"> 
                    <Nav className="mr-auto" onSelect={(selectedKey) => menuSelect(selectedKey)}>
                        { JSXmenu }
                    </Nav>
                </Navbar.Collapse>

                <Nav className="mr" onSelect={(selectedKey) => buttonSelect(selectedKey)}>
                     { JSXbutton }
                </Nav>
            </Navbar>

    )
}



const RoutingWrap = () => {
  const { userStatus, setUserStatus } = useContext(UserContext);

  console.debug('RoutingWrap userStatus:',userStatus);

  var output;

  switch (userStatus) {
   
      case ContentFsm.SIGNING_IN:
      case ContentFsm.SIGNED_OUT:       output = (unauthRoutes.map(item => <Route path={item.path} Component={item.component} key={item.component}/>));   
                                        break;

      case ContentFsm.SIGNING_OUT:     
      case ContentFsm.SIGNED_IN:        output = (authRoutes.map(item => <Route path={item.path} Component={item.component} key={item.component}/>));   
                                        break;

      case ContentFsm.REVALIDATE:         
      case ContentFsm.UNDEFINED:        
      default:                          output = (revalidateRoutes.map(item => <Route path={item.path} Component={item.component} key={item.component}/>));   
                                      
  }
    
  return ( 
      <Routes>  
        {output}
      </Routes>  
  );
};

const Header = () => {
  return ( <MenuWrap /> );      // displays menus and signin/out button, menus based on context
}

const Content = () => {
  return ( <RoutingWrap /> );   // provides routes for navigation, routes based on context
}

const Footer = () => {
 
  const rowStyle = {
    height: '4rem',
    backgroundColor: 'rgba(41, 43, 44, 0.1)',
    //marginTop: '1rem'
  };

  const textStyle:any = {
      textAlign: "center",
  }

  return (
    <Container fluid="lg">
        <Row style={rowStyle}>
            <Col>
                <p />
                <p style={textStyle}>&copy; <a href="https://www.easypeasydigital.co.uk">easypeasyDigital</a> 2024  {environment}</p>
            </Col>
        </Row>         
    </Container>

  )
}



export const Layout = () => {
  const [userStatus, setUserStatus] = useState<string>(ContentFsm.UNDEFINED);
  const [lastMenuPath, setLastMenuPath] = useState<string>(window.location.pathname);
  //const [dmarc, setDmarc] = useState<DmarcData>({validDomains: [], domains:[]});
  //const [dmarc, setDmarc] = useState<DmarcData>({domain:[]});
  const [dmarc, setDmarc] = useState<DmarcData[]>([]);
  //const [dmarc, setDmarc] = useState({domain:[]});
  //const [dmarc, setDmarc] = useState<DmarcDataRecord[]>([]);

  Amplify.configure(awsconfig);

  return (
    <Authenticator.Provider>
      <UserContext.Provider value={{userStatus, setUserStatus}}>
        <MenuPathContext.Provider value={{lastMenuPath, setLastMenuPath}}>
          <DmarcContext.Provider value={{dmarc, setDmarc}}>
            <BrowserRouter>
              <Container fluid="lg">
                <Header />    
                <Content />      
                <Footer />
              </Container>
            </BrowserRouter>
          </DmarcContext.Provider>
        </MenuPathContext.Provider> 
      </UserContext.Provider>
    </Authenticator.Provider>

  )
};

