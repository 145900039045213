export const dnsQuery = async (domain:string, type:string) => {
    return (LocalDnsQuery(domain, type));
};

const LocalDnsQuery  = async (domain:string, type:string) => {

    console.log('LocalDnsQuery',domain);

    const query:string = `https://dns.google/resolve?name=${domain}&type=${type}`;

    let response;

    try {

        response = await fetch(query);
    
        response = await response.json();

    } catch {

        response = [{Question:[ {name: domain, type: type}]}]; // failed so fake a (non) result
    }


    console.log('response', response);

    return response;
     
 /*   
    fetch(query)
    .then(response => {
        console.log('response', response); 
        response.json()
        .then(response => {
            const result = {...response};
            console.log('response', response);
            return response;
        });
    })
    .catch(error => console.error(error));
     
    //const result = await fetchResult.json;
*/

    //console.log('dnsQuery output', fetchResult, result);

};


