
import * as React from 'react'

// https://react-icons.github.io/react-icons/search
import { FaCheck } from "react-icons/fa";
import { BsCheck, BsFileXFill, BsFileX, BsChevronDown, BsChevronUp, BsCloudArrowDown, BsCloudDownloadFill, BsCloudDownload,  BsFillCaretDownFill, BsFillCaretUpFill} from "react-icons/bs";


//export const contentFsm = { UNDEFINED: 'UND', SIGNING_IN: 'REQSI', SIGNING_OUT: 'REQSO', SIGNED_IN: 'SI', REVALIDATE: 'REV' };
export enum ContentFsm  { UNDEFINED = 'UND', SIGNING_IN = 'REQSI', SIGNING_OUT = 'REQSO', SIGNED_IN = 'SI', SIGNED_OUT = 'SO', REVALIDATE = 'REV' };
 
export enum LoadingFsm  { UNDEFINED = 'UND', INITIALISING = 'INI', EMPTY = 'EMPTY', FOUND = 'OK', REFRESH = 'RESET' }
 
export enum RAG  { Red = '#e84b4f', Amber = '#ffcb2e', Green = '#5fd85f' }



const styleGreen = {color: '#00FF00', fontWeight: 900}
const styleRed = {color: '#FF0000', fontWeight: 900}

//const tick = <span style={spanStyleGreen}>✓</span>;

const cross = <span style={styleRed}>X</span>
const tick = <FaCheck style={styleGreen}/>


export const Icon = { ASC: <BsFillCaretUpFill />, DESC: <BsFillCaretDownFill />, DOWNLOAD: <BsCloudDownload />, GOOD: tick, BAD: cross, EXPANDED: <BsChevronUp />, CONTRACTED: <BsChevronDown />};

//export enum Icon { ASC = '🔼', DESC = '🔽', GOOD = <span style={spanStyleGreen}>✓</span>, BAD = '⛔️', EXPANDED = '▼', CONTRACTED = '▶️', OTHER = '🔼🔽✅⚠️⛔️⬆️⬆️⬇️🔄🔃⬅️➡️ℹ️️❎❌✅🔴🟠🟢🔵⚫️✓✔︎✖︎✗✓✔︎✕✖︎🚦⚠️✗✘👍☹️⍻✓✔️✔️𐄂✓☑' } 
 
export const dkimSelectors = [ 'google', 
'selector1', 
'selector2',
'everlytickey1',
'everlytickey2', 
'eversrv',  
'k1', 
'k2', 
'mxvault', 
'dkim',
'q9d',
'kl',
'kl2',
'default',
's1',
's2'
];