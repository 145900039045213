export const dmarcDetail = async (domain:string, dkimSelectors:string[]) => {
    return (LocalDmarcDetail(domain, dkimSelectors));
};

const LocalDmarcDetail  = async (domain:string, dkimSelectors:string[]) => {

    console.log('LocalDmarcDetail',domain);

    const prefix = 'https://dns.google/resolve';
/*
     const urls = ipArray.map((ip) => {
        const suffix = ipv6(ip) ? ".ip6.arpa" : ".in-addr.arpa";
        const reverse = expandAndReverseIp(ip);
        const url = `${prefix}${reverse}${suffix}`;
        return url;
     });
*/     

    
    type query = {
        prefix: string;
        type: number;
        request: string;
    }

    var query:query[] = dkimSelectors.map(item => ({ request: 'dkim', prefix: `${item}._domainkey.`, type: 16}));

        query = [   ...query,
                    {request: 'spf', prefix: '', type: 16},
                    {request: 'dmarc', prefix: '_dmarc.', type: 16},         
                    {request: 'mx', prefix: '', type: 15}, 
                    {request: '5', prefix: '', type: 5}                   
                ]



    const urls:string[] = query.map(url => ( `${prefix}?name=${url.prefix}${domain}&type=${url.type}`));

    console.log('urls', urls);


    const list = urls.map((ip) => fetch(ip));                       // setup promises with fetch

    let fetchData:any[] = [];                                       // fetch all
    await Promise.all(list)
    .then(response => {
       fetchData = response.map((item) => item.json());
    })
    .catch(error => console.error(error));

    const output:any[] = [];                                        // get all results
    await Promise.all(fetchData)                                    
    .then(results => {
        console.log('fetchData', results);
        for (let index=0; index<results.length; index++) { //((result) => {
        //results.forEach((result) => {
            if ((results[index].Status === 0) && (results[index].hasOwnProperty('Answer'))) {
                results[index].Answer.forEach((answer:any) => {
                    if (results[index].Question[0].type === answer.type) {
                        const Answer = {...answer};
                        Answer.request = query[index].request;
                        if (Answer.name !== results[index].Question[0].name) {
                            Answer.intermediateName = Answer.name;
                            Answer.name             = results[index].Question[0].name;
                        }
                        output.push({...Answer});
                    }
                });
            }
        }
    })

    console.log('dmarcDetail output', output);


    return output;
};

