



export const Home = () => {
    console.debug('Home');
    const pStyle = {color: '#ff0000', backgroundColor: '#00ff00'};
    return (
        <>
        <h1>Home</h1>
  

         </>
    )
}