import { Button, Col, Form, Row, InputGroup, Tab, Tabs } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { DmarcContext } from './context';
import { LoadingFsm } from './const'

interface ViewDashboard {
    domain: string;
    onChangeDate: any;
}

export const ViewDashboard = (props:ViewDashboard) => {
    const { dmarc, setDmarc } = useContext(DmarcContext);
    const [ fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);


    useEffect(() => {
        console.log('useEffect');

        if (fsm ===  LoadingFsm.UNDEFINED) {

            const dmarcIndex = dmarc.findIndex(item => item.domain === props.domain);
            if (dmarcIndex < 0) {
               
                setFsm(LoadingFsm.EMPTY);                                       // no match for valid domains
            
            } else if (dmarc[dmarcIndex].hasOwnProperty('startDate')) {
               
                console.log('DEFAULT', dmarc[dmarcIndex].startDate);
                setFsm(LoadingFsm.FOUND);   
                // set default
                //setFsm((dmarc[dmarcIndex].report?.length === 0) ? LoadingFsm.EMPTY : LoadingFsm.FOUND);
            
            } else {

                setFsm(LoadingFsm.INITIALISING);   
            }
        };
    }, []);

    if (LoadingFsm.FOUND) {
        const dmarcIndex = dmarc.findIndex(item => item.domain === props.domain);
        const startDate = dmarc[dmarcIndex].startDate!;
        return (
            <>
                <SelectDateRange onChangeDate={props.onChangeDate} startDate={startDate} />
            </>
        )
    } else {
        return null;
    }


}



interface SelectDateRange {
    onChangeDate: any;
    startDate:string;
}


const dropDownValues:any = [
                                {name: 'Yesterday',             value:  -1 },
                                {name: 'Last week (7 days)',    value:  -7 },
                                {name: 'Last 2 weeks',          value: -14 },
                                {name: 'Last 4 weeks',          value: -28 },
                            ]

export const setDateByDays = (days:string) => {
   // const date:Date = new Date();   

    let today:Date = new Date();
    let date:Date = new Date();

    date.setDate(today.getDate() + parseInt(days));

    //const start:string = (new Date(startDate).getTime()/ 1000).toString(10);

    
    //const dateAsString:string = date.toISOString().substring(0,10)


    return ( date );
}

export const SelectDateRange = (props:SelectDateRange) => {

    const handleChange = (event:any) => {
        //const startDate = setDateByDays(event.target.value);

        props.onChangeDate(event.target.value);

        console.log('changed', event.target.value, )
    }
/*
    let today:Date = new Date();
    props.startDate
*/
    return (
        <>
          <Form noValidate  >
                <Form.Group as={Col} md="5" controlId="specialised">
                    <Form.Label>Date Range</Form.Label>
                      <Form.Select 
                            name='specialised' 
                            aria-label="Select Type"
                          
                            value = {props.startDate}
                            onChange={handleChange}

                        >
    
                        { dropDownValues.map((item:any) => (<option key={item.value}  value={item.value}>{item.name}</option>)) }
                      </Form.Select>
                </Form.Group>
            </Form>
        </>
    )

}