import { Auth, API } from 'aws-amplify';
import { apiGateway } from './config';

const  ApiGet = async (path: string) => {


    apiGateway.apiInit.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  
    console.log('apiGet', apiGateway.apiName,apiGateway.apiPath+path, apiGateway.apiInit);

    //return await API.get(apiName, path, myInit);
    return await API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}

const ApiHeaders = async () => {
    
    //const headers = apiGateway.apiInit.headers;
    apiGateway.apiInit.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //const headers = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //return headers;
}


const  ApiGetNoWait = async (path: string) => {

    await ApiHeaders();

    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}

const  ApiGetArray = async (getArray: object[]) => {

    await ApiHeaders();

    const output = getArray.map(item => API.get(apiGateway.apiName, apiGateway.apiPath+item, apiGateway.apiInit));

    console.log('ApiGetArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}

/*
const  ApiCall = async (item: object) => {

    console.log('PATH', item);

    const path = item.resource;
    let response =null;

    switch (item.method) {
        case    'POST': const init = apiGateway.apiInit;
                        init.body = item.body; //JSON.stringify(item.body);
                        response = API.post(apiGateway.apiName, apiGateway.apiPath+path, init); console.log('POST', init); break;
        case    'GET' :
        default       : response = API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit); console.log('GET');
    }


    return response;
}


const  ApiCallArray = async (callArray) => {

    await ApiHeaders();

    console.log('ApiCallArray', Array.isArray(callArray));

    const output = Array.isArray(callArray) ? callArray.map(item => ApiCall(item)) : ApiCall(callArray); // single entry


    console.log('ApiCallArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}
*/

export {ApiGet, ApiGetNoWait, ApiGetArray, ApiHeaders};
  