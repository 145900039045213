import {ApiGet} from './Api'

export const dmarcUserDomains = async () => {

    var query:string = `DMARCmydomains`;
    
    const result = await ApiGet(query);

    return (result);
}

