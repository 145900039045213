import * as React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'

import { Table as BTable } from 'react-bootstrap'

import { dmarcReport } from './dmarcReport'
import { dmarcDomain } from './dmarcDomain'

import { Aggregate } from './types'

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'




  //const dataString = JSON.stringify(data);

 // const getData = (dataString: string) => {      
 //xxxxx   const parsed = JSON.parse(dataString);
 /*
  const getData = (parsed: object[]) => {      
   
    

    //const extracted = parsed.map ((item: any ) => { 
    const extracted = parsed.map ((item: any) => { 

       // console.log('auth', JSON.stringify(item.record.auth_results, null, 2));
        
        var startDate = new Date(item.report_metadata.date_range.begin*1000);
        var endDate = new Date(item.report_metadata.date_range.end*1000);

        const newItem: Aggregate = {
            domain:             item.policy_published.domain,
            source_ip:          item.record.row.source_ip,
            count:              item.record.row.count,
            delivery_status:    "",
            dmarc:              (item.record.row.policy_evaluated.spf === "pass") || (item.record.row.policy_evaluated.dkim === "pass") ? "pass" : "fail",
            spf:                item.record.row.policy_evaluated.spf,
            dkim:               item.record.row.policy_evaluated.dkim,            
            disposition:        item.record.row.policy_evaluated.disposition,
            spf_authentication: item.record.auth_results.spf.result,              // WILL BE AN ARRAY
            dkim_alignment:     (item.record.identifiers.header_from === item.policy_published.domain) ? "aligned": "not aligned",  // needs to check headers 
            reporter:           item.report_metadata.org_name,
            date:               `${startDate.toISOString().substring(0,10)} - ${endDate.toISOString().substring(0,10)}`,
    
            //date:               `${item.report_metadata.date_range.begin} - ${item.report_metadata.date_range.end}`,
            header_from:        item.record.identifiers.header_from,
        }

    
        return newItem;
    });

    return (extracted);
}
*/

//const columnHelper = createColumnHelper<Aggregate>();


//const columns: ColumnDef<Aggregate, any>[] = [
const columns: ColumnDef<Aggregate>[] = [
  {
    header: 'Name',
    //footer: props => props.column.id,
    columns: [
      {
        accessorKey: 'domain',
        cell: info => info.getValue(),
        header: () => <span>Domain</span>,
        //footer: props => props.column.id,
      },
      {
        accessorKey: 'source_ip',
        cell: info => info.getValue(),
        header: () => <span>Source IP</span>,
        id: 'source_ip',
        //footer: props => props.column.id,
      },
      {
        accessorKey: 'source_ptr',
        cell: info => info.getValue(),
        header: () => <span>Source PTR</span>,
        id: 'source_ptr',
        //footer: props => props.column.id,
      },
      {
        accessorKey: 'source_domain',
        cell: info => info.getValue(),
        header: () => <span>Source Domain</span>,
        id: 'source_domain',
        //footer: props => props.column.id,
      },
      {
        accessorKey: 'count',
        cell: info => info.getValue(),
        header: () => <span>Count</span>,
        //footer: props => props.column.id,
      },      
      {
        accessorKey: 'delivery_status:',
        //accessorFn: row => row.spf,
        cell: info => info.getValue(),
        header: () => <span>Delivery Status</span>,
        //footer: props => props.column.id,
      },       
      {
        accessorKey: 'dmarc',
        //accessorFn: row => row.spf,
        cell: info => info.getValue(),
        header: () => <span>DMARC</span>,
        //footer: props => props.column.id,
      }, 
      {
        accessorKey: 'spf',
        //accessorFn: row => row.spf,
        cell: info => info.getValue(),
        header: () => <span>SPF</span>,
        //footer: props => props.column.id,
      },      
      {
        accessorKey: 'dkim',
        cell: info => info.getValue(),
        header: () => <span>DKIM</span>,
        //footer: props => props.column.id,
      },    
      {
        accessorKey: 'spf_alignment',
        cell: info => info.getValue(),
        header: () => <span>SPF alignment</span>,
        //footer: props => props.column.id,
      },  
      {
        accessorKey: 'spf_authentication',
        cell: info => info.getValue(),
        header: () => <span>SPF authentication result</span>,
        //footer: props => props.column.id,
      },   
      {
        accessorKey: 'dkim_alignment',
        cell: info => info.getValue(),
        header: () => <span>DKIM alignment</span>,
        //footer: props => props.column.id,
      },  
      {
        accessorKey: 'dkim_authentication',
        cell: info => info.getValue(),
        header: () => <span>dkim_authentication</span>,
        //footer: props => props.column.id,
      },  
      {
        accessorKey: 'reporter',
        cell: info => info.getValue(),
        header: () => <span>Reporter</span>,
        //footer: props => props.column.id,
      },  
      {
        accessorKey: 'date',
        cell: info => info.getValue(),
        header: () => <span>Date</span>,
        //footer: props => props.column.id,
      }, 
      {      
        accessorKey: 'header_from',
        cell: info => info.getValue(),
        header: () => <span>from</span>,
        //footer: props => props.column.id,
      },        
      {      
        accessorKey: 'end_date',
        cell: info => info.getValue(),
        header: () => <span>end</span>,
        //footer: props => props.column.id,
      },  
      {      
        accessorKey: 'envelope_from',
        cell: info => info.getValue(),
        header: () => <span>envelope from</span>,
        //footer: props => props.column.id,
      },        
      {      
        accessorKey: 'envelope_to',
        cell: info => info.getValue(),
        header: () => <span>envelope to</span>,
        //footer: props => props.column.id,
      },  
      {      
        accessorKey: 'sk',
        cell: info => info.getValue(),
        header: () => <span>sk</span>,
        //footer: props => props.column.id,
      }, 
      {      
        accessorKey: 'index',
        cell: info => info.getValue(),
        header: () => <span>index</span>,
        //footer: props => props.column.id,
      },  
    ],
  },
]

//console.log('dataString', JSON.stringify(JSON.parse(dataString),null, 2));


export const Table = () => {
  const [data, setData] = React.useState<Aggregate[]>([])

  React.useEffect(() => {

    console.log('LOG TABLE USE EFFECT================================');
    if (data.length === 0 ) {
      console.log('data is null');
      //dmarcReport('easypeasydigital.co.uk')

       //const start:Date = new Date('2023-10-04');
       //dmarcReport({domain: 'canterburybears.com'})
  
      //const start:Date = new Date('2023-10-04');
      
      //const start:Date = new Date('2023-10-13');     
      // const end:Date = new Date();
      // dmarcReport({domain: 'canterburybears.com', begin: start, end: end})
      
 
       const start:Date = new Date('2023-10-15');     
       const end:Date = new Date('2023-10-15');
       dmarcReport({domain: 'canterburybears.com', begin: start, end: end})

       //dmarcReport({domain: 'florcultura.org.uk'})

       //dmarcReport({domain: 'canterburybears.com', sk: 'begin#1696118400#end#1696204800#report_id#6bd15d76e2ca402d9627604e213ce783'})
       //dmarcReport({domain: 'canterburybears.com', sk: 'begin#1696464000#end#1696550400#report_id#54f12023939e4518bbf1edcf3d227613'})

       

      //const start:Date = new Date('2023-09-28');
      //dmarcReport('easypeasydigital.co.uk', start, start)

      .then(report => {
        
        setData(report);

        const ipList = report.map((item) => (item.source_ip));
        
        const dedup = Array.from(new Set(ipList));

        //console.log('iplist', ipList, dedup);

        dmarcDomain(dedup)
        .then(domains => {
   
            console.log('domains', data);

            //console.log('FIRST', domains[0].ip, report[0].source_ip)

            var messages = 0;
            const newReport:any[] = report.map((item:Aggregate) => { 

              //const test = ['149.72.235.154']
              
              //const match = test.find((domain:any) => domain.ip == item.source_ip);
              messages += item.count;

              const match = domains.find((domain:any) => domain.ip == item.source_ip);
              if (match !== undefined) {
                item.source_ptr     = match.host;
                item.source_domain  = match.domain;
              }

              return (item);
              
              console.log('domain match:', match);

              return (item.source_ip);
          });

          console.log('STATISTICS messages', messages, 'FOUND');
          
          setData(newReport);

            //const newReport = report.map((item) =>  (
            //  item.source_ip
            //    //domains.find((domain:any) => { console.log(domain.ip, item.source_ip)
            //    //});
            //));
          console.log('newReport', newReport);

        //setData(data);
        });
      });
    }
    //console.log('effect', data2, JSON.stringify(data2,null,2));
  }, []);

  if (data.length === 0) {
    console.log('NOT FOUND');
    return (null);
  } else {
    //console.log('FOUND', data); 
    return (
      <>
      <TableDetail data={data}/>
      </>
      //TableDetail(data)
    )
  }
}

//export default function Table() {
export const TableDetail = (props: {data: Aggregate[]}) => {

  const [sorting, setSorting] = React.useState<SortingState>([])

  //const [data, setData] = React.useState(makeData(10))
  //const rerender = () => setData(makeData(10))
  //const [data, setData] = React.useState(getData(dataString))
  /////////const [data, setData] = React.useState<object[]>([])
  //const [data, setData] = React.useState(JSON.parse(dataString))
  /////const rerender = () => setData(getData(dataString))

  //const [data2, setData2] = React.useState<object[]>([])

  //console.log('START------------------------------', props.data);
  //setData2(await dmarcReport('easypeasydigital.co.uk'));

  //const [data, setData] = React.useState(JSON.parse(dataString))
  //const rerender = () => setData(getData(dataString))
  //const testdata = await dmarcReport('easypeasydigital.co.uk');
  //const data3 = await dmarcReport('easypeasydigital.co.uk');

  const data:Aggregate[] = props.data;
  

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })
/*
  React.useEffect(() => {

    if (data.length === 0 ) {
      console.log('data is null');
      dmarcReport('easypeasydigital.co.uk')
      .then(result => {
        setData(result);
      });
    }

    //console.log('effect', data2, JSON.stringify(data2,null,2));
  }, [data]);

*/
/*
  if (data.length === 0) {
    return (null);
  }
  else {
  */
  return (

    <>
          {console.log('data',  data)}


    <div className="p-2">
      <BTable striped bordered hover responsive size="sm">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (

                    <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
          
                      {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                      )}
                      {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null
                      }
                      </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </BTable>

    </div>

    </>
  )
}


/*

      <div className="h-4">
        <button onClick={() => rerender()} className="border p-2">
          Rerender
        </button>
      </div>
*/  